<template>
  <BlankLayout>
    <v-layout align-center justify-center>
      <div>
        <div style="text-align: center">
          <img
            src="@/assets/logo.png"
            width="200"
            style="padding-bottom: 20px"
          />
        </div>
        <p style="text-align: center; white-space: pre-wrap">
          {{ $t('views.end.message') }}<br /><br />
          <a href="https://fair-votes.com/live">FairVotes LIVE</a>
        </p>
      </div>
    </v-layout>
  </BlankLayout>
</template>

<script>
import BlankLayout from '@/components/layouts/Blank.vue'

export default {
  name: 'End',

  components: {
    BlankLayout,
  },

  data() {
    return {
      title: this.$t('views.end.title'),
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.end.title')
      document.title = this.title
    },
  },

  created: function () {
    document.title = this.title
  },
}
</script>
